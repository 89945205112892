import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/mcruz/Documents/Developer/gatsby/appuro-hortodaquinta-com/src/components/layout.js";
import MdxLayout from "../components/mdxLayout.js";
import MdxCoverLayout from "../components/mdxCoverLayout.js";
import Seo from '../components/seo.js';
import ogImage from '../../static/og-image/oggimage.png';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Seo title="Serviços" description="O Horto da Quinta foi criado no dia 9 de Maio de 2001 e tem como objectivo a venda de plantas e materiais de decoração, entre outros serviços especializados." image={'https://www.hortodaquinta.com' + ogImage} siteUrl={'https://www.hortodaquinta.com' + props.pageContext.frontmatter.path} mdxType="Seo" />
    <MdxCoverLayout mdxType="MdxCoverLayout" />
    <MdxLayout mdxType="MdxLayout">
      <h1>{props.pageContext.frontmatter.title}</h1>
      <p><strong parentName="p">{`Venda de plantas e árvores`}</strong></p>
      <p>{`No Horto da Quinta pode encontrar uma grande variedade de plantas e árvores ao seu dispor. Venha visitar as nossas instalações e veja por si mesmo a quantidade e diversidade de plantas e árvores que temos para si.`}</p>
      <p>{`Se tem algumas dúvidas sobre qual o melhor tipo de planta ou árvore para colocar no seu jardim pergunte a um dos nossos colaboradores. Eles darão sugestões segundo o seu caso, conforme se quer colocar as plantas no interior ou exterior da sua casa para além de lhe dizerem quais as vantagens e desvantagens de cada uma das opções.`}</p>
      <p>{`O nosso leque de árvores e plantas engloba variedades de interior e exterior.`}</p>
      <p><strong parentName="p">{`Construção de Cascatas, Lagos e Riachos`}</strong></p>
      <p>{`Para quem vive num local com bastante espaço à volta da casa as nossas cascatas, lagos e riachos são uma solução que vão trazer mais beleza à propriedade.`}</p>
      <p>{`Temos uma variedade de cascatas, lagos e riachos que o vão surpreender. De acordo com o espaço disponível e também tendo em conta o que pretende temos várias opções à escolha.`}</p>
      <p>{`A presença de uma fonte de água vai tornar o espaço envolvente mais verde. Ao fazer uma cascata ou lago vai tornar a sua casa o ponto turístico preferido dos seus amigos. Se já tem uma piscina em casa, suba a fasquia e junte-lhe uma cascata. Com um cenário destes até vai pensar que está num local paradisíaco.`}</p>
      <p>{`As nossas construções têm também um tratamento de água biológico, que recorre a bactérias e microorganismos para a limpeza e purificação das águas utilizadas. Não existem químicos ou outros poluentes envolvidos no processo.`}</p>
      <p><strong parentName="p">{`Decoração de iluminações`}</strong></p>
      <p>{`Com as nossas iluminações, luz é coisa que não lhe vai faltar.`}</p>
      <p>{`Temos uma variedade de iluminações para várias ocasiões e locais. Jardins, lagos e cascatas são alguns dos locais a que damos mais brilho. Com o recurso à iluminação pode passar bons momentos dentro de água já depois do sol se pôr.`}</p>
      <p>{`Temos também disponíveis iluminações de acordo com a época do ano em que se encontra, o Natal por exemplo, ou então iluminações para épocas festivas.`}</p>
      <p>{`Se gosta quando vê na televisão, aquelas iluminações que dão outro valor ao momento em que se encontra contacte-nos. Fuja da rotina e experimente uma nova decoração de Natal, com mais brilho. As luzes que se compram nas lojas dão alguma cor mas nada como uma iluminação profissional feita por nos.`}</p>
      <p>{`Depois de experimentar não vai querer outra coisa.`}</p>
      <p><strong parentName="p">{`Terraplanagem`}</strong></p>
      <p>{`Os nossos serviços de terraplanagem estão ao seu dispor.`}</p>
      <p>{`Se tem um terreno aos altos e baixos e quer aplaina-lo opte pelos nossos serviços. Conseguimos aplainar ou aterrar um terreno para uma grande variedade de utilizações, como a construção de jardins, pequenas construções ou simplesmente para dar um aspecto mais plano ao terreno.`}</p>
      <p>{`O trabalho é desempenhado por profissionais qualificados na área.`}</p>
      <p><strong parentName="p">{`Sistema de rega`}</strong></p>
      <p>{`Se tem um jardim em sua casa e precisa de o regar pode optar por um dos nossos sistemas de rega.`}</p>
      <p>{`Nem imagina o tempo que vai poupar. Em vez de ter de andar com uma mangueira sempre que é preciso regar, os nossos sistemas de rega fazem-no por si. Temos vários sistemas de acordo com o que cada situação exige. Temos sistemas de dispersão para regar uma grande área como um relvado, ou então sistemas mais focalizados em locais específicos para rega localizado de plantas. Podem programar o circuito de rega de modo a começar de forma automática a determinada hora ou manualmente quando assim o desejar.`}</p>
      <p><strong parentName="p">{`Projectos Paisagísticos`}</strong></p>
      <p>{`Se tem espaço livre mas não sabe de que forma o decorar recorra aos nossos serviços e em conjunto com as suas opiniões criaremos um projecto paisagístico.`}</p>
      <p>{`Os nossos projectos dão o melhor aspecto ao exterior da sua casa. Utilizamos e combinamos materiais de decoração com produtos naturais para criar uma imagem ao seu estilo.`}</p>
      <p>{`Se não tem qualquer ideia para o espaço deixe-nos trabalhar que lhe apresentaremos uma ideia inicial que depois será trabalhada e alterada de acordo com o que pretender. Pode tornar o seu jardim um espaço amplo e decorado com pequenas plantas numa ideia mais simples ou então optar por uma coisa mais desenvolvida com árvores, plantas e vários outros materiais, tudo depende do que quer.`}</p>
      <p>{`Visite-nos e tire todas as suas dúvidas.`}</p>
      <p><strong parentName="p">{`Plantações Agrícolas`}</strong></p>
      <p>{`A nossa empresa é especializada em realizar plantações agrícolas.`}</p>
      <p>{`Se tem um terreno e quiser fazer um pomar ou até mesmo uma plantação contacte-nos, nos jar realizamos várias plantações e todas com grande sucesso.`}</p>
      <p>{`Se estiver interessado em fazer uma plantação não excite em contactar-nos!`}</p>
    </MdxLayout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      